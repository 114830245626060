import { List, Show, Edit, FilterButton, useNotify, Create, required, ReferenceField } from 'react-admin'
import { useWatch } from "react-hook-form";
import { Datagrid, FormDataConsumer, DateField, DateInput, TextField, AutocompleteInput, NumberField, NumberInput, ReferenceInput, SelectField, SelectInput, PasswordInput, BooleanField, EmailField, BooleanInput, SimpleShowLayout, TopToolbar, ListButton, ShowButton, SimpleForm, TextInput} from 'react-admin';
import moment from 'moment';
import licenseTypes from './licenseTypes';
import {PerpetualVersionList, PerpetualVersionChoices} from './perpetualVersions';
import { FormLabel } from '@mui/material';

const LicenseListActions = () => (
    <TopToolbar>
    </TopToolbar>
);

const licenseFilters = [
    <TextInput label="Email" source="email" defaultValue="" alwaysOn/>,
    <SelectInput label="Subject" source="subject" defaultValue="" alwaysOn choices={licenseTypes}/>,
];

export const LicenseList = () => (
    <List filters={licenseFilters}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="id"/>
            <ReferenceField source="userId" reference="users" label="User" />
            <ReferenceField source="productId" reference="products" label="Product" />
            <TextField source="subject"/>
            <NumberField source="numSeats"/>
            <TextField source="durationIso"/>
            <DateField source="startDate"/>
            <DateField source="expirationDate"/>
            <TextField source="token"/>
        </Datagrid>
    </List>
);

const emailFilterToQuery = email => ({ email: email });

export const LicenseCreate = (props) => {
    const transform = data => {
        let duration_iso = 'P';
        if (data.durationYears > 0) { duration_iso += `${data.durationYears}Y` }
        if (data.durationMonths > 0) { duration_iso += `${data.durationMonths}M` }
        if (data.durationDays > 0) { duration_iso += `${data.durationDays}D` }
        delete data.durationYears;
        delete data.durationMonths;
        delete data.durationDays;

        if (duration_iso != 'P') {  // no duration == perpetual
            data.durationIso = duration_iso;
        }
        return data
    };

    return (
        <Create {...props} transform={transform}>
            <SimpleForm>
                <ReferenceInput label="Product" source="productId" reference="products">
                    <SelectInput validate={required()} fullWidth/>
                </ReferenceInput>
                <TextInput source="subject" defaultValue="" fullWidth/>

                <ReferenceInput label="User" source="userId" reference="users">
                    <AutocompleteInput
                        optionText="email"
                        suggestionLimit="5"
                        filterToQuery={emailFilterToQuery}
                        fullWidth
                    />
                </ReferenceInput>
                <div>
                    <NumberInput source="durationYears" defaultValue={0} />
                    <NumberInput source="durationMonths" defaultValue={0} />
                    <NumberInput source="durationDays" defaultValue={0} />
                </div>
                
                <NumberInput source="numSeats" defaultValue={1}/>
                <BooleanInput label="Is this a Trial license?" source="isTrial" />
            </SimpleForm>
        </Create>
    );
}
