import { React, useEffect, useState } from 'react';
import axios from 'axios';

import {
    localStorageStore,
    fetchUtils,
    Layout,
    Admin,
    Resource,
    ListGuesser,
} from 'react-admin';

import { generateFingerprint } from 'utils';

import { dataProvider, HTTPSetter } from 'admin/dataProvider';
import authProvider from 'admin/authProvider';
import UserIcon from 'svg/users-icon';

import { defaultTheme } from 'react-admin';
import indigo from '@mui/material/colors/indigo';
import pink from '@mui/material/colors/pink';
import red from '@mui/material/colors/red';

import Header from 'components/Header';
import { UserList, UserShow, UserCreate } from 'resources/users';
import { ReleaseList } from 'resources/releases';
import { PriceList } from 'resources/prices';
import { ProductList } from 'resources/products';
import { PurchaseTransactionList, PurchaseTransactionShow, PurchaseTransactionCreate } from 'resources/purchaseTransactions';
import { LicenseList, LicenseShow, LicenseCreate} from 'resources/licenses';
import { LicenseBundleList, LicenseBundleCreate, LicenseBundleShow } from 'resources/licenseBundles';
import { DistributorCreate, DistributorList } from 'resources/distributors';
import { LicenseDistributionCreate, LicenseDistributionList } from 'resources/licenseDistributions';
import { DistributionWebhookList, DistributionWebhookShow, DistributionWebhookCreate } from 'resources/distributionWebhooks';



//SET THE BASE URL
axios.defaults.baseURL = process.env.REACT_APP_HOST_URL;
axios.defaults.headers.common['Access-Control-Allow-Origin'] = process.env.REACT_APP_HOST_URL;

//MANAGE CORS
if (process.env.NODE_ENV === "development") {
    axios.defaults.headers.common['Access-Control-Allow-Credentials'] = true;
    axios.defaults.headers.common['Bypass-Tunnel-Reminder'] = true;
    //REACT_APP_HOST_URL is the server url
    //TO RUN TESTS ON MOBILE DEVICE VIA localtunnel: REACT_APP_HOST_URL=ttps://cowardly-bird-11.loca.lt npm run start
}

//DISABLE CONSOLE LOG/ETC
if (process.env.NODE_ENV === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

//DEFAULT HTTP RESPONSE HANDLER
axios.interceptors.response.use((response) => {
  //STATUS 2xx
  return response;
}, (err) => {

  if (!err.response) {  //network error - service unreachable
    // authProvider.logout();
  }
  else if (err.response.status === 401) { //401 unauthorized
    // authProvider.logout();
  }
  else if (err.response.status === 400) { //400 bad-request
    console.log('ERROR 400!', err.response.data.error);
  }
  return Promise.reject(err);
});


//react-admin THEME SETUP
const admin_theme = {
    ...defaultTheme,
    palette: {
        primary: indigo,
        secondary: pink,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    },
}


//DEFINE LAYOUT COMPONENT
const admin_layout = (props) => <Layout {...props} appBar={Header} />;


const store = localStorageStore();
function App(props) {

    //AUTH TOKEN FINGERPRINTING
    useEffect(()=>{
        generateFingerprint();
    }, []);

    return (
        <Admin 
            layout={admin_layout}
            theme={admin_theme} 
            dataProvider={dataProvider}
            authProvider={authProvider}
            store={store}
        >
        <Resource
            name="users"
            icon={UserIcon}
            list={UserList}
            show={UserShow}
            create={UserCreate}
            recordRepresentation={(record) => `${record.contactName}`}
        />
        <Resource
            name="purchase-transactions"
            list={PurchaseTransactionList}
            create={PurchaseTransactionCreate}
            show={PurchaseTransactionShow}
        />
        <Resource
            name="licenses"
            list={LicenseList}
            create={LicenseCreate}
        />
        <Resource
            name="license-bundles"
            list={LicenseBundleList}
            show={LicenseBundleShow}
            // recordRepresentation={(record) => `${record.purchaser}: ${record.description}`}
        />
        <Resource
            name="releases"
            list={ReleaseList}
            recordRepresentation={(record) => `${record.uuid} - ${record.version}`}
        />
        <Resource
            name="products"
            list={ProductList}
            recordRepresentation={(record) => `${record.name}`}
        />
        <Resource
            name="prices"
            list={PriceList}
            recordRepresentation={(record) => `${record.name}`}
        />
        <Resource
            name="distributors"
            list={DistributorList}
            create={DistributorCreate}
            recordRepresentation={(record) => `${record.name}`}
        />
        <Resource
            name="license-distributions"
            list={LicenseDistributionList}
            create={LicenseDistributionCreate}
            recordRepresentation={(record) => `${record.distributorId}: ${record.description}`}
        />
        <Resource
            name="distribution-webhooks"
            list={DistributionWebhookList}
            show={DistributionWebhookShow}
            create={DistributionWebhookCreate}
            recordRepresentation={(record) => `${record.licenseDistributionId}`}
        />
        </Admin>
    );
};

export default App;
